@use "colors";

.button {
  background-color: transparent;
  block-size: 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0;
  z-index: 2;

  svg {
    stroke: #fff;
    transition: 0.2s;

    g {
      &:first-child {
        opacity: 1;
        transition: opacity 0s 0.2s;

        line {
          transform: translateY(0);
          transition: transform 0.2s 0.2s;
        }
      }

      &:last-child {
        opacity: 0;
        transition: opacity 0s 0.2s;

        line {
          transform: rotate(0deg);
          transform-origin: center;
          transition: transform 0.2s;
        }
      }
    }
  }

  &[aria-expanded="true"] {
    svg {
      stroke: #fff;

      g {
        &:first-child {
          opacity: 0;

          line {
            transition: transform 0.2s;

            &:first-child {
              transform: translateY(5px);
            }

            &:last-child {
              transform: translateY(-5px);
            }
          }
        }

        &:last-child {
          opacity: 1;

          line {
            transition: transform 0.2s 0.2s;

            &:first-child {
              transform: rotate(45deg);
            }

            &:last-child {
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
}
